/* eslint-disable no-restricted-syntax */
import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { H2 } from '@allthingswww/client-act-shared';

import Survey from '../components/Survey';
import surveyData from '../../static/surveys/peopleRegisteringForAnEvent.json';
import Layout from '../components/Layout';
import { updatedCheckoutDataSuccess, getEventCheckoutData } from '../redux/eventCheckout';
import { getEventByID } from '../redux/event';
import { getMyInfo } from '../redux/member';

export default (props) => {
  let entranceFee = null;
  const dispatch = useDispatch();
  const myInfo = useSelector(getMyInfo);
  const checkOutData = useSelector(getEventCheckoutData);
  // eslint-disable-next-line react/destructuring-assignment
  const eventDetails = useSelector(getEventByID(props['*']));
  const eventItems = !_.isEmpty(eventDetails) ? JSON.parse(eventDetails[0].eventItems) : [];
  if (!_.isEmpty(eventDetails) && eventDetails[0].maxNumberOfAttendees) {
    surveyData.pages[0].elements[0].maxPanelCount = eventDetails[0].maxNumberOfAttendees;
  }
  const checkOutDataCopy = _.cloneDeep(checkOutData);
  checkOutDataCopy.attendees = checkOutDataCopy.attendees || [];

  if (myInfo.fullName) {
    checkOutDataCopy.attendees[0] = {
      fullName: myInfo.fullName,
      email: myInfo.email,
      address: myInfo.memberAddress1,
      phoneNumber: myInfo.phone,
    };
  }

  if (_.isEmpty(eventDetails)) {
    return (
      <Layout
        title="No event details"
        subtitle=""
      >
        <div>No details about this event</div>
      </Layout>

    );
  }
  const {
    memberFee = 0,
    nonMemberFee = 0,
  } = eventDetails[0];

  const checkIfUserIsAMember = () => {
    if (_.isEmpty(myInfo)) {
      entranceFee = nonMemberFee;
      return entranceFee;
    }
    if (!myInfo.role.isMember) {
      entranceFee = nonMemberFee;
      return entranceFee;
    }
    if (myInfo.role.isMember) {
      entranceFee = memberFee;
      return entranceFee;
    }
    return entranceFee;
  };

  const onSubmit = async (surveyResults) => {
    checkIfUserIsAMember();
    const { data } = surveyResults;
    console.log('entranceFee :>> ', entranceFee);
    dispatch(updatedCheckoutDataSuccess({ attendees: data.items }));
    if (_.isEmpty(eventItems)) {
      dispatch(updatedCheckoutDataSuccess({
        amountToBePaidDetails: [],
        allItemsToBePaid: { 'Entrance Fee': entranceFee * data.items.length },
      }));
      return navigate(
        `/checkout/${props['*']}`,
        {
          state: { amountToBePaidDetails: [], attendees: data.items },
        },
      );
    }
    navigate(
      `/register-event-items/${props['*']}`,
      {
        state: { attendees: data.items },
      },
    );
  };

  return (
    <Layout title="Register Attendees" subtitle="" condensed>
      <H2>Register attendees</H2>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={
          !_.isEmpty(checkOutDataCopy) && { items: checkOutDataCopy.attendees }
        }
      />
    </Layout>
  );
};
